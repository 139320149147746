import Vue from 'vue';

export default function openModal (component, props) {
    return new Promise((resolve, reject) => {
        const modal = new Vue({
            methods: {
                closeHandler (fn, arg) {
                    fn(arg);
                    modal.$destroy();
                    // use Node.removeChild instead of ChildNode.remove for compatibility
                    // https://caniuse.com/#search=ChildNode.remove()
                    modal.$el.parentNode.removeChild(modal.$el);
                },
            },

            render (createElement) {
                return createElement(component, {
                    props,
                    on: {
                        'close-modal': (arg) => this.closeHandler(resolve, arg),
                        'cancel-modal': (arg) => this.closeHandler(reject, arg),
                    },
                });
            },
        }).$mount();

        document.body.appendChild(modal.$el);
    });
}

export { default as SessionExpirationModal } from './SessionExpirationModal.vue';
export { default as ErrorModal } from './ErrorModal.vue';
