
import axios from 'axios';
import { reactive, set } from 'vue';
import { inPhase } from 'rocketship-lifecycle/functional-utils';

import api from '../../api';

const getDefaultState = () => reactive({
    // These environment variables are embedded by vue-cli and Webpack's
    // `DefinePlugin`. May be used as prefix for cookie names, etcetera.
    // https://cli.vuejs.org/guide/mode-and-env.html#environment-variables
    name: process.env.VUE_APP_PACKAGE_NAME,
    deployPath: process.env.VUE_APP_TB2_DEPLOY_PATH,

    channel: determineChannel(),
    affiliateId: '',

    // `__initialAppState__` is set by a `<script>` tag in the rendered HTML
    // from the template that bootstraps this app. It should only be used to
    // communicate values from the server that can't easily wait until
    // `/api/app`'s response, commonly just `defaultRoute` to signal errors.
    ...window.__initialAppState__,
});

const state = getDefaultState();

updateAxiosLanguage(state.locale);

const getters = {
    inPhase: (state) => (phases) => inPhase(phases, state.phase),
};

const mutations = {
    updateAppState (state, data) {
        for (const key in data) {
            set(state, key, data[key]);

            if (key === 'locale') {
                updateAxiosLanguage(data.locale);
            }
        }
    },
    hashAffiliateUpdate (state, affiliateId) {
        // Only update if an affiliate is not already present.
        state.affiliateId = state.affiliateId || affiliateId;
    },
};

const actions = {
    async loadAppState ({ dispatch, commit }) {
        try {
            const response = await axios.get(`${api.base}/app${window.location.search}`);
            commit('updateAppState', response.data);
            return response;
        }
        catch (err) {
            console.error(
                'error making app state call',
                err.message,
                err,
            );

            throw err;
        }
    },
};

function determineChannel () {
    // http://stackoverflow.com/a/8876069/575849
    return Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0,
    ) < 768 ?
        'm' :
        'def';
}

function updateAxiosLanguage (locale) {
    if (locale) {
        // Ensure API responses respect user's chosen locale.
        axios.defaults.headers.common['Accept-Language'] = locale.replace('_', '-');
    }
    else {
        delete axios.defaults.headers.common['Accept-Language'];
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
